//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from "./mixins";
import Request from "@/webrtc/server/internal/Request";
export default {
  name: "chatHistoryDialog",
  mixins: [mixins],
  components: {
    Dialog: () => import("./dialog"),
  },
  data: () => ({
    name: "chatHistoryDialog",
  }),
  methods: {
    fetch() {
      if (this.loading) return;
      this.loading = true;
      this.data = [];
      var queryData = {
        ...this.options,
        page: this.pageData.pageIndex,
        limit: this.pageData.pageSize,
      };
      if (this.isLocalStorage) {
        var self = this;
        var request = new Request();
        request.Url = "/Process/Chat";
        request.ContentType = "Json";
        const token = this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.Body = JSON.stringify(queryData);
        request.OnError = function () {
          self.loading = false;
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (result.State) {
            self.data = result.Data.Datas;
            self.pageData.totalNum = result.Data.Total;
          }
          self.loading = false;
        };
        this.$emit("SendRequest", request, null, (error) => {
          self.$notify({
            message: `${error}`,
            type: "error",
          });
          self.loading = false;
        });
      } else {
        this.$http
          .post("/Monitors/Chat.ashx", queryData)
          .then((result) => {
            if (result.res === 0) {
              this.data = result.data.Datas;
              this.pageData.totalNum = result.data.Total;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
