var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chatHistoryDialog"},[_c('Dialog',{attrs:{"name":_vm.name,"title":_vm.title || '聊天记录'},on:{"closed":_vm.closed}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('el-table',{attrs:{"data":_vm.data,"border":""}},[_c('el-table-column',{attrs:{"prop":"name","label":"员工","show-overflow-tooltip":"","align":"center"}}),_c('el-table-column',{attrs:{"prop":"FocusFormName","label":"标题","show-overflow-tooltip":"","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Content","label":"聊天内容","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var Content = ref_row.Content;
var ImageName = ref_row.ImageName;
return [_c('el-popover',{attrs:{"trigger":"hover","placement":"top"}},[_c('span',{staticClass:"text-overflow",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(_vm._s(Content))]),_c('div',{staticClass:"chatHistoryPopoverRoot"},[_c('div',{staticClass:"left"},[_c('el-image',{attrs:{"src":_vm.handleImageSrc(ImageName),"preview-src-list":_vm.previewSrcList,"fit":"cover"}})],1),_c('div',{staticClass:"right"},[_vm._v(_vm._s(Content))])])])]}}])}),_c('el-table-column',{attrs:{"prop":"Type","label":"聊天类型","show-overflow-tooltip":"","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Time","label":"时间","show-overflow-tooltip":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var Time = ref.row.Time;
return [_vm._v(_vm._s(Time.timeFormat("yyyy-MM-dd HH:mm")))]}}])}),_c('el-table-column',{attrs:{"prop":"ImageName","label":"截图","show-overflow-tooltip":"","align":"center","width":"110px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ImageName = ref.row.ImageName;
return [_c('el-image',{staticStyle:{"width":"80px","height":"50px"},attrs:{"src":_vm.handleImageSrc(ImageName),"preview-src-list":_vm.previewSrcList,"fit":"cover","z-index":99999}})]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.pageData.pageIndex,"page-sizes":[20, 30, 40, 50],"page-size":_vm.pageData.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.pageData.totalNum},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }